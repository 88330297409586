<template>
  <b-container fluid="xl" class="mt-4">
    <h2>Articles</h2>

    <b-overlay :show="loading" rounded="sm">
      <div v-for="article in articles" :key="article.id">
        <hr />
        <b-row @click="openArticle(article.slug)" style="cursor: pointer">
          <b-col v-if="article.show_thumbnail" cols="*">
            <b-img-lazy
              fluid
              style="max-height: 75px"
              :src="article.thumbnail_url"
              class="mb-3"
            />
          </b-col>

          <b-col>
            <h4>{{ article.title }}</h4>
            <p>{{ article.description }}</p>
          </b-col>
        </b-row>
      </div>

      <hr />

      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
    </b-overlay>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      articles: [],
      currentPage: 1,
      rows: 0,
      perPage: 10,
    };
  },
  methods: {
    openArticle(slug) {
      this.$router.push({ path: `/articles/${slug}` });
    },
  },
  watch: {
    async currentPage(to) {
      this.loading = true;

      try {
        let articles = await this.$http.get(
          this.$store.state.apiURL +
            "/articles/firm/" +
            this.$store.state.firmID +
            "/public",
          {
            params: {
              page: to,
            },
          }
        );

        this.articles = articles.data.data;
        this.currentPage = articles.data.page.position.current;
        this.rows = articles.data.page.data.total;
      } catch (error) {
        this.handleError(error);
      }

      this.loading = false;
    },
  },
  async created() {
    try {
      let articles = await this.$http.get(
        this.$store.state.apiURL +
          "/articles/firm/" +
          this.$store.state.firmID +
          "/public"
      );

      this.articles = articles.data.data;
      this.currentPage = articles.data.page.position.current;
      this.rows = articles.data.page.data.total;
    } catch (error) {
      this.handleError(error);
    }

    this.loading = false;
  },
};
</script>

<style lang="scss">
@import "../../scss/theme.scss";
</style>